<template>
  <div class="kennel-form">
    <form @submit.prevent="calculateSquareFootage">
      <div v-for="(pet, index) in pets" :key="index" class="pet-form">
        <h4>Pet {{ index + 1 }}</h4>
        <button v-if="index > 0" type="button" @click="removePet(index)" class="remove-pet-btn">Remove</button>
        <div class="form-group">
          <label :for="'breed' + index">Breed</label>
          <select v-model="pet.breed" :id="'breed' + index" @change="breedChanged(pet, index)">
            <option value="">Select a breed</option>
            <option v-for="breedOption in breedOptions" :key="breedOption.name" :value="breedOption">
              {{ breedOption.name }}
            </option>
            <option value="Unknown">Unknown breed</option>
          </select>
        </div>

        <div class="form-group">
          <label :for="'length' + index">Length (inches)</label>
          <input type="number" v-model.number="pet.length" :id="'length' + index" :readonly="isLengthReadonly(pet)" />
        </div>

        <div class="form-group">
          <label :for="'weight' + index">Weight (pounds)</label>
          <input type="number" v-model.number="pet.weight" :id="'weight' + index" @input="manualWeightChange(pet)" />
        </div>
      </div>

      <div v-if="pets.length < 3">
        <button type="button" @click="addPet">+ Add Another Pet</button>
      </div>
      <br>
      <div class="form-group">
        <label for="lengthOfStay">Length of Stay in Days</label>
        <input type="number" v-model.number="lengthOfStay" id="lengthOfStay" />
      </div>

      <button type="submit">Calculate</button>
    </form>

    <div v-if="squareFootage">
      <h4>Calculated Square Footage: {{ Math.round(squareFootage) }} sq. ft.</h4>
      <!-- <h4>Recommended Kennel Size: {{ recommendedKennelSize }}</h4> -->
      <div v-if="squareFootage > 36">
        <p style="color: red;">The square footage needed based on the information entered exceeds any kennel sizes available.<br>
          </p>
          <p>Note: For optimal comfort and safety of your pet, explore individual lodging options designed specifically for their needs.</p>
      </div>
      <div v-if="pets.length > 1" class="calculation-breakdown">
        <div v-if="petCalculationBreakdown[1]">
          <h5>Pet 2 Calculation:</h5>
          <p>{{ petCalculationBreakdown[1] }}</p>
        </div>
        <div v-if="pets.length > 2 && petCalculationBreakdown[2]">
          <h5>Pet 3 Calculation:</h5>
          <p>{{ petCalculationBreakdown[2] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pets: [
        { breed: '', length: 0, weight: 0, weightChanged: false }
      ],
      breedOptions: [],
      lengthOfStay: 1,
      squareFootage: null,
      recommendedKennelSize: null,
      petCalculationBreakdown: [] // Added property to store breakdown
    };
  },
  mounted() {
    this.fetchBreedOptions();
  },
  methods: {
    addPet() {
      if (this.pets.length < 3) {
        this.pets.push({ breed: '', length: 0, weight: 0, weightChanged: false });
      }
    },
    removePet(index) {
      this.pets.splice(index, 1);
    },
    calculateSquareFootage() {
      let largestPetSpace = 0;
      let totalSquareFootage = 0;
      this.petCalculationBreakdown = []; // Reset breakdown

      this.pets.forEach((pet, index) => {
        let spaceWithWeight = 0;
        let spaceWithoutWeight = 0;

        // Use the manually changed weight if it has been changed, otherwise use the breed's min weight
        if (!pet.weightChanged && pet.breed && pet.breed !== 'Unknown') {
          pet.weight = pet.breed["Min Weight (pounds)"];
        }

        if (pet.weight) {
          spaceWithWeight = this.calculateSpaceWithWeight(pet.weight, this.lengthOfStay);
        }

        spaceWithoutWeight = this.calculateSpaceMinusWeight(pet.length, this.lengthOfStay);

        let petSpace = Math.max(spaceWithWeight, spaceWithoutWeight);

        if (petSpace > largestPetSpace) {
          largestPetSpace = petSpace;
        }

        // Add breakdown for each pet
        if (index === 0) {
          this.petCalculationBreakdown[index] = `Pet ${index + 1} Calculation: Breed: ${pet.breed ? pet.breed.name : 'Unknown'}, Length: ${pet.length} inches, Weight: ${pet.weight} pounds. Calculated space: ${petSpace.toFixed(2)} sq. ft. This is taken at 100%.`;
        } else {
          this.petCalculationBreakdown[index] = `Pet ${index + 1} Calculation: Breed: ${pet.breed ? pet.breed.name : 'Unknown'}, Length: ${pet.length} inches, Weight: ${pet.weight} pounds. Calculated space: ${(petSpace * 0.5).toFixed(2)} sq. ft. This is 50% of the largest pet's space.`;
        }
      });

      // Calculate the total square footage
      totalSquareFootage = largestPetSpace + ((this.pets.length - 1) * largestPetSpace * 0.5);
      this.squareFootage = totalSquareFootage.toFixed(2);

      // Add note for total calculation
      if (this.pets.length > 1) {
        this.petCalculationBreakdown.push(`Total square footage: ${this.squareFootage} sq. ft. Note: The largest pet's space is taken at 100% and each subsequent pet at 50% of the largest pet's space.`);
      }
    },
    async fetchBreedOptions() {
      try {
        const response = await fetch('/petData.json');
        const data = await response.json();
        this.breedOptions = data.dog_breeds;
      } catch (error) {
        console.error('Error fetching breed options:', error);
      }
    },
    breedChanged(pet) {
      if (pet.breed && pet.breed !== 'Unknown') {
        pet.length = pet.breed["Max Length (inches)"];
        if (!pet.weightChanged) {
          pet.weight = pet.breed["Min Weight (pounds)"];
        }
        pet.weightChanged = false; // Reset the weightChanged flag after changing the breed
      }
    },
    manualWeightChange(pet) {
      pet.weightChanged = true;
    },
    calculateSpaceMinusWeight(lengthInches, days) {
      if (isNaN(lengthInches) || isNaN(days)) {
        return 0; // Return 0 if any input is invalid
      }

      let baseSpace = ((lengthInches + 6) * (lengthInches + 6)) / 144;

      if (days > 5 && days <= 30) {
        baseSpace *= 1.5;
      } else if (days > 30 && days < 180) {
        baseSpace *= 2;
      } else if (days >= 180) {
        baseSpace *= 3;
      }

      return baseSpace;
    },
    calculateSpaceWithWeight(weight, days) {
      let baseSpace;

      if (isNaN(weight) || isNaN(days)) {
        return 0; // Return 0 if any input is invalid
      }

      if (weight <= 15) {
        baseSpace = 9;
      } else if (weight <= 45) {
        baseSpace = 12;
      } else if (weight <= 65) {
        baseSpace = 15;
      } else if (weight <= 85) {
        baseSpace = 20;
      } else if (weight <= 125) {
        baseSpace = 25;
      } else {
        baseSpace = 36;
      }

      if (days > 5 && days <= 30) {
        baseSpace *= 1.5;
      } else if (days > 30 && days < 180) {
        baseSpace *= 2;
      } else if (days >= 180) {
        baseSpace *= 3;
      }

      return baseSpace;
    },
    calculateSize(lengthOrWeight) {
      if (lengthOrWeight < 12) {
        return "XS";
      } else if (lengthOrWeight < 15) {
        return "SM";
      } else if (lengthOrWeight < 20) {
        return "M";
      } else if (lengthOrWeight < 25) {
        return "LG";
      } else if (lengthOrWeight < 36) {
        return "XL";
      } else if (lengthOrWeight >= 36) {
        return "XXL";
      } else {
        return ""; // Return empty string for other cases
      }
    },
    isLengthReadonly(pet) {
      return pet.breed !== 'Unknown';
    }
  },
  watch: {
    pets: {
      handler() {
        this.pets.forEach((pet) => {
          if (!pet.weightChanged) {
            this.breedChanged(pet);
          }
        });
      },
      deep: true
    },
    squareFootage(newValue) {
      this.recommendedKennelSize = this.calculateSize(newValue);
    }
  }
};
</script>

<style>
.kennel-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.pet-form {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  position: relative;
}

.remove-pet-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.remove-pet-btn:hover {
  background-color: #333;
}

.form-group {
  margin-bottom: 16px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
select {
  width: 75%; /* Set the width to 75% of the current size */
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #369870;
}

.calculation-breakdown {
  margin-top: 10px;
}
</style>
